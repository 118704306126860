import url from 'url';

export const isURL = (value: string): boolean => {
  const parsed = url.parse(value);

  if (parsed.protocol?.startsWith('http')) {
    return Boolean(parsed.hostname);
  }

  return false;
};
