import {
  Colors,
  IconCheck,
  Radius,
  Row,
  space,
  Weight,
} from '@workos-inc/ui-kit';
import { ProviderLogo } from 'components/provider-logo';
import { ConnectionType, DirectoryType } from 'graphql/generated';
import React, { ReactElement, ReactNode } from 'react';
import styled, { css } from 'styled-components';

export interface ProviderBoxProps {
  active: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children?: ReactNode;
  provider: ConnectionType | DirectoryType;
  label?: string;
  color?: string;
}

export const ProviderBox = ({
  active,
  children,
  label,
  provider,
  onClick,
  color = Colors.BrandPrimary,
}: ProviderBoxProps): ReactElement => (
  <StyledProviderBox
    active={active}
    color={color}
    hasLabel={Boolean(label)}
    onClick={onClick}
  >
    <ProviderLogo provider={provider} />
    {label && <span>{label}</span>}
    <Check color={color} visible={active} />
    {children}
  </StyledProviderBox>
);

interface CheckProps {
  visible: boolean;
  color: string;
}

const Check = ({ visible, color }: CheckProps) => (
  <CheckContainer bg={color} opacity={visible ? 1 : 0}>
    <IconCheck color={Colors.White} strokeWidth="3.5" />
  </CheckContainer>
);

const CheckContainer = styled(Row)`
  border-radius: 50%;
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  transition: 0.25s ease-in-out;
  position: absolute;
  right: -8px;
  top: -12px;
`;

const StyledProviderBox = styled.button<{
  active?: boolean;
  color: string;
  hasLabel: boolean;
}>`
  appearance: none;
  border: 1px solid ${Colors.TonedLightGray};
  border-radius: ${Radius.Medium};
  height: 84px;
  width: 172px;
  transition: 0.25s ease-in-out;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: ${Colors.White};

  &:hover {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  }

  &:focus {
    outline: none;
    border: 1px solid ${(p) => p.color};
  }

  & > svg {
    width: 140px;
    height: ${(p) => (p.hasLabel ? '24px' : '32px')};
  }

  ${(p) =>
    p.active &&
    css`
      background-color: #f8faff;
      border-color: ${p.color} !important;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  }`}

  & > span {
    font-weight: ${Weight.Regular};
    font-size: 15px;
    color: ${Colors.TonedMidGray};
    margin-top: ${space.spacing(2)};
  }
`;
