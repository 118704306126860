import { Colors, IconSwitch, space } from '@workos-inc/ui-kit';
import { ProviderLogo } from 'components/provider-logo';
import { SwitchProviderModal } from 'components/sso/switch-provider-modal';
import { Step, StepsNav } from 'components/steps-nav';
import { StoreContext } from 'components/store-provider';
import { useRouter } from 'next/router';
import React, { FC, useContext, useState } from 'react';
import styled from 'styled-components';
import { trackEvent } from 'utils/track-event';

export type SidebarProps = {
  steps: Step[];
  stepsWithError: number[] | undefined;
};

export const Sidebar: FC<Readonly<SidebarProps>> = ({
  steps,
  stepsWithError = [],
}) => {
  const router = useRouter();
  const {
    ssoProvider: [provider],
  } = useContext(StoreContext);

  const [isSwitchProviderModalOpen, setIsSwitchProviderModalOpen] =
    useState<boolean>(false);

  return (
    <>
      <Aside>
        <LogoContainer>
          {provider && <ProviderLogo provider={provider} />}
        </LogoContainer>

        <SwitchProviderButton
          onClick={() => setIsSwitchProviderModalOpen(true)}
        >
          <IconSwitch color={Colors.TonedMidGray} />
          Switch Identity Provider
        </SwitchProviderButton>

        <StepsNav
          onChangeStep={(step: number) => void router.push(`/sso/${step}`)}
          provider={provider}
          steps={steps}
          stepsWithError={stepsWithError}
        />
      </Aside>

      <SwitchProviderModal
        isOpen={isSwitchProviderModalOpen}
        setIsOpen={setIsSwitchProviderModalOpen}
        trackEvent={trackEvent}
      />
    </>
  );
};

const Aside = styled.aside`
  height: calc(100vh - 200px);
  position: sticky;
  top: 0px;
`;

const LogoContainer = styled.div`
  width: ${space.spacing(24)};
`;

const SwitchProviderButton = styled.button`
  background: none;
  border: none;
  color: ${Colors.TonedMidGray};
  cursor: pointer;
  margin: ${space.spacing(5)} 0 ${space.spacing(10)};
  outline: none;
`;
