import { graphql } from 'utils/graphql';

export const trackEvent = (
  event_name: string,
  attributes?: Record<string, unknown>,
): void => {
  void graphql().TrackEvent({
    event_name,
    attributes,
  });
};
