import {
  CopyIcon,
  ObfuscateIcon,
  Spinner,
  Tooltip,
} from '@workos-inc/component-library';
import { Card } from 'components/card';
import { Value } from 'components/input/value';
import { Label } from 'components/label';
import copy from 'copy-text-to-clipboard';
import React, { CSSProperties, useState } from 'react';

type CopyInputProps = {
  label: string;
  onCopy?: () => void;
  style?: CSSProperties;
  value?: string | null;
  type?: 'text' | 'password';
};

export const CopyInput: React.FC<Readonly<CopyInputProps>> = ({
  label,
  onCopy,
  value,
  style,
  type,
}) => {
  const [copied, setCopied] = useState<boolean>(false);
  const [isPasswordVisible, setPasswordVisible] = useState<boolean>(false);

  const handleCopy = (): void => {
    setTimeout(() => setCopied(false), 1000);

    if (!value) {
      return;
    }

    setCopied(true);

    copy(value);

    if (onCopy) {
      onCopy();
    }
  };

  const valueToDisplay =
    value && type === 'password' && !isPasswordVisible
      ? value.replace(/./g, '•')
      : value;

  const overlays = [
    {
      key: 'copy-icon',
      content: (
        <Tooltip content={copied ? 'Copied' : 'Copy'}>
          <i className="appearance-none">
            <CopyIcon
              isCopied={copied}
              onClick={handleCopy}
              testId="copy-icon"
            />
          </i>
        </Tooltip>
      ),
    },
    type === 'password' && {
      key: 'obfuscate-icon',
      content: (
        <Tooltip content={!isPasswordVisible ? 'View' : 'Hide'}>
          <i className="appearance-none">
            <ObfuscateIcon
              isObfuscated={!isPasswordVisible}
              onClick={() => setPasswordVisible(!isPasswordVisible)}
              testId="obfuscate-icon"
            />
          </i>
        </Tooltip>
      ),
    },
  ].filter(Boolean) as { key: string; content: JSX.Element }[];

  return (
    <Card style={style}>
      <Label>{label}</Label>

      <div className="mt-2">
        <Value>
          {value ? (
            <div className="relative w-full">
              {valueToDisplay}
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                {overlays.map((icon, index) => (
                  <div
                    key={icon.key}
                    className={index > 0 ? 'ml-2' : undefined}
                  >
                    {icon.content}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <Spinner />
          )}
        </Value>
      </div>
    </Card>
  );
};
