import { useFeatureToggle } from '@flopflip/react-broadcast';

export type FeatureFlags = {
  adpOidcAdminPortal: boolean;
  breatheHrAdminPortal: boolean;
  googleOAuthProvider: boolean;
  hibobAdminPortal: boolean;
  hidePoweredByWorkos: boolean;
  oktaOinAdminPortalSso: boolean;
  workdayAdminPortal: boolean;
  fourthHrAdminPortal: boolean;
  oneLoginOacAdminPortalSso: boolean;
  ripplingSsoAdminPortal: boolean;
  inputSamlCertificateMetadataUrl: boolean;
  useGeneratedConnectionEntityId: boolean;
};

export const defaultFeatureFlags: FeatureFlags = {
  adpOidcAdminPortal: true,
  breatheHrAdminPortal: false,
  googleOAuthProvider: true,
  hibobAdminPortal: false,
  hidePoweredByWorkos: true,
  oktaOinAdminPortalSso: false,
  workdayAdminPortal: false,
  fourthHrAdminPortal: false,
  oneLoginOacAdminPortalSso: false,
  ripplingSsoAdminPortal: false,
  inputSamlCertificateMetadataUrl: false,
  useGeneratedConnectionEntityId: false,
};

export const useFeature = (feature: keyof FeatureFlags): boolean =>
  useFeatureToggle(feature);
