import { Colors, Column, Input, space } from '@workos-inc/ui-kit';
import { ProviderBox } from 'components/provider-box';
import { motion } from 'framer-motion';
import { ConnectionType, DirectoryType } from 'graphql/generated';
import React, { ChangeEvent, ReactElement, useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { useFeature } from 'utils/feature-flags';

interface ProviderSelectionProps<T> {
  isSearchable?: boolean;
  providers: readonly T[];
  selection?: T;
  providerLabels?: { [key: string]: string };
  onSelection: (provider: T) => void;
}

export const ProviderSelection = <T extends ConnectionType | DirectoryType>({
  providers,
  selection,
  onSelection,
  providerLabels,
  isSearchable = false,
}: Readonly<ProviderSelectionProps<T>>): ReactElement => {
  const theme = useContext(ThemeContext);
  const [searchInput, setSearchInput] = useState('');
  const isGoogleOAuthEnabled = useFeature('googleOAuthProvider');
  const isHibobEnabled = useFeature('hibobAdminPortal');
  const isWorkdayEnabled = useFeature('workdayAdminPortal');
  const isFourthHrEnabled = useFeature('fourthHrAdminPortal');
  const isRipplingSsoEnabled = useFeature('ripplingSsoAdminPortal');
  const isBreatheHrEnabled = useFeature('breatheHrAdminPortal');
  const isAdpOidcEnabled = useFeature('adpOidcAdminPortal');

  return (
    <Container>
      {isSearchable && (
        <Input
          id="search"
          isCompressed={true}
          name="search"
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setSearchInput(event.target.value)
          }
          placeholder="Search identity providers..."
          value={searchInput}
        />
      )}
      <ProvidersList
        $numProviders={providers.length}
        animate="show"
        initial="hidden"
        variants={listVariants}
      >
        {providers
          .filter((provider) => {
            if (provider === ConnectionType.GoogleOAuth) {
              return !!isGoogleOAuthEnabled;
            } else if (provider === DirectoryType.Hibob) {
              return !!isHibobEnabled;
            } else if (provider === DirectoryType.Workday) {
              return !!isWorkdayEnabled;
            } else if (provider === DirectoryType.FourthHr) {
              return !!isFourthHrEnabled;
            } else if (provider === ConnectionType.RipplingSaml) {
              return !!isRipplingSsoEnabled;
            } else if (provider === DirectoryType.BreatheHr) {
              return !!isBreatheHrEnabled;
            } else if (provider === ConnectionType.AdpOidc) {
              return !!isAdpOidcEnabled;
            }

            return provider;
          })
          .filter((provider) => provider.match(new RegExp(searchInput, 'i')))
          .map((provider) => (
            <ProviderListItem
              key={provider}
              data-testid={`provider-item__${
                provider as ConnectionType | DirectoryType
              }`}
              variants={itemVariants}
            >
              <ProviderBox
                active={selection === provider}
                color={theme.primary}
                label={providerLabels?.[provider]}
                onClick={() => onSelection(provider)}
                provider={provider}
              />
            </ProviderListItem>
          ))}
      </ProvidersList>
    </Container>
  );
};

const Container = styled(Column)`
  align-items: center;
  max-width: 556px;
  input::placeholder {
    opacity: 1;
    color: ${Colors.TonedMidGray};
  }
`;

const ProvidersList = styled(motion.ul)<{ $numProviders: number }>`
  display: grid;
  grid-template-columns: repeat(
    ${(props) => (props.$numProviders > 4 ? 3 : 2)},
    max-content
  );
  grid-auto-rows: max-content;
  grid-gap: ${space.spacing(5)};
  margin-top: ${space.spacing(6)};
  margin-bottom: ${space.spacing(4)};
`;

const ProviderListItem = styled(motion.li)`
  list-style: none;
  height: 100%;

  svg {
  }
`;

const listVariants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      delay: 0.2,
      staggerChildren: 0.05,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, scale: 0.1 },
  show: { opacity: 1, scale: 1 },
};
