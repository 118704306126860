import { Button } from '@workos-inc/component-library';
import { Box, Colors, space } from '@workos-inc/ui-kit';
import { Error } from 'components/input/error';
import React, { FormEvent, ReactNode } from 'react';
import styled, { css } from 'styled-components';

interface FormProps {
  onSubmit: () => void;
  error?: Error;
  isInline?: boolean;
  isLoading?: boolean;
  isUpdate?: boolean;
  disabled?: boolean;
  children: ReactNode;
  secondaryText?: string;
  secondaryAction?: () => void;
}

interface Error {
  message: string;
}

export const Form: React.FC<Readonly<FormProps>> = ({
  onSubmit,
  error,
  isInline,
  isLoading,
  isUpdate,
  disabled,
  children,
  secondaryText,
  secondaryAction,
}) => (
  <StyledForm
    isError={!!error}
    onSubmit={(event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      onSubmit();
    }}
  >
    <Container isInline={isInline}>
      {children}

      <Box
        display="flex"
        justifyContent="flex-end"
        marginTop={space.spacing(8)}
      >
        {!!secondaryText && secondaryAction && (
          <Button
            appearance="minimal"
            className="mr-4"
            onClick={secondaryAction}
            type="button"
          >
            {secondaryText}
          </Button>
        )}

        <Button disabled={disabled} isLoading={isLoading} type="submit">
          {isUpdate ? 'Update' : 'Continue to Next Step'}
        </Button>
      </Box>
    </Container>

    {error && <Error>{error?.message}</Error>}
  </StyledForm>
);

const Container = styled.div<{ isInline?: boolean }>`
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.isInline
      ? css`
          flex-direction: row;
          align-items: flex-end;

          button {
            margin-left: ${space.spacing(2)};
          }
        `
      : {}}
`;

const StyledForm = styled.form<{ isError?: boolean }>`
  fieldset:not(:last-of-type) {
    margin-bottom: ${space.spacing(6)};
  }

  ${(props) =>
    props.isError &&
    css`
      input {
        border-color: rgba(208, 61, 61, 0.5) !important;
      }
      label {
        color: ${Colors.Red};
      }
    `}
`;
