import 'tailwindcss/tailwind.css';
import { BugsnagErrorBoundary } from 'components/error-boundaries';
import { FeatureFlagProvider } from 'components/feature-flag-provider';
import type { AppProps } from 'next/app';
import React from 'react';
import { initDataDogRum } from 'utils/telemetry/datadog';

const MyApp: React.FC<Readonly<AppProps>> = ({ Component, pageProps }) => {
  initDataDogRum();

  return (
    <BugsnagErrorBoundary>
      <FeatureFlagProvider>
        <Component {...pageProps} />
      </FeatureFlagProvider>
    </BugsnagErrorBoundary>
  );
};

export default MyApp;
