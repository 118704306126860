import { Button } from '@workos-inc/component-library';
import { Colors, IconFile, Row, space, Weight } from '@workos-inc/ui-kit';
import { Card } from 'components/card';
import { Label } from 'components/label';
import { Spinner } from 'components/spinner';
import React, { useState } from 'react';
import styled, { CSSProperties } from 'styled-components';

const Anchor = styled.a`
  color: ${(p) => p.theme.primary};
  font-weight: ${Weight.Medium};
  font-size: 15px;
  text-decoration: none;
  display: flex;
  align-items: center;
`;

const DownloadAnchor = styled.a`
  text-decoration: none;
`;

type DownloadInputProps = {
  filename: string;
  label: string;
  onDownload?: () => void;
  style?: CSSProperties;
  value?: string;
};

export const DownloadInput: React.FC<Readonly<DownloadInputProps>> = ({
  filename,
  label,
  onDownload,
  style,
  value,
}) => {
  const [downloaded, setDownloaded] = useState<boolean>(false);

  const handleDownload = (): void => {
    setTimeout(() => setDownloaded(false), 2000);

    setDownloaded(true);

    if (onDownload) {
      onDownload();
    }
  };

  return (
    <Card style={style}>
      <Label color={Colors.TonedMidGray}>{label}</Label>
      <Row
        alignItems="center"
        justifyContent="space-between"
        marginTop={space.spacing(2)}
      >
        {value ? (
          <>
            <Anchor download={filename} href={value}>
              <IconFile style={{ marginRight: space.spacing(1) }} />
              {filename}
            </Anchor>
            <DownloadAnchor download={filename} href={value}>
              <Button
                appearance={downloaded ? 'minimal' : undefined}
                onClick={handleDownload}
              >
                {downloaded ? 'Downloaded!' : 'Click to download'}
              </Button>
            </DownloadAnchor>
          </>
        ) : (
          <>
            <Spinner />
            <Button disabled={true} onClick={() => null}>
              Click to download
            </Button>
          </>
        )}
      </Row>
    </Card>
  );
};
