import { Button } from '@workos-inc/component-library';
import {
  Box,
  Colors,
  Grid,
  IconCheck,
  Radius,
  space,
  Weight,
} from '@workos-inc/ui-kit';
import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { FileField, TextField } from 'components/fields';
import { StoreContext } from 'components/store-provider';
import { Article, H1, Paragraph } from 'components/typography';
import { motion } from 'framer-motion';
import { ConnectionFragment } from 'graphql/generated';
import { SSOErrors } from 'interfaces/errors';
import { UpdatableConnectionFields } from 'interfaces/step-props';
import { useRouter } from 'next/router';
import React, {
  ChangeEvent,
  ChangeEventHandler,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useContext,
} from 'react';
import styled from 'styled-components';
import { testConnection } from 'utils/test-connection';

interface ConfigurationCompleteProps {
  completeConnection: () => Promise<void>;
  errorFields?: ErrorFields;
  errors?: SSOErrors;
  isLoading?: boolean;
  providerLabel: string;
  onFileInput?: ChangeEventHandler<HTMLInputElement>;
  onInputChange?: ChangeEventHandler<HTMLInputElement>;
  connectionUpdatedFields: UpdatableConnectionFields;
  setConnectionUpdatedFields: Dispatch<
    SetStateAction<UpdatableConnectionFields>
  >;
}

interface ErrorFields {
  [key: string]: {
    label: string;
    step: number;
  };
}

export const ConfigurationComplete: React.FC<
  Readonly<ConfigurationCompleteProps>
> = ({
  completeConnection,
  errorFields,
  errors,
  isLoading,
  providerLabel,
  onInputChange,
  onFileInput,
  connectionUpdatedFields,
  setConnectionUpdatedFields,
}) => {
  const { query, push } = useRouter();
  const setActiveStep = (step: number) => {
    void push(`/sso/${step}`);
  };

  const {
    connection: [connection],
  } = useContext(StoreContext);
  let errorCount = 0;

  if (errors) {
    const keys = Object.keys(errors) as (keyof ConnectionFragment)[];

    errorCount = keys.reduce((count, field) => {
      if (errors?.[field]?.value === connection?.[field]) {
        return ++count;
      }

      return count;
    }, 0);
  }

  const getFieldComponent = (field: string): ReactElement | null => {
    switch (field) {
      case 'oidc_client_id':
        return (
          <Box key={field}>
            <Card>
              <TextField
                error={errors?.oidc_client_id}
                label={errorFields?.oidc_client_id?.label}
                name="oidc_client_id"
                onChange={onInputChange}
                value={connection?.oidc_client_id}
              />
            </Card>

            <StepButton
              setActiveStep={setActiveStep}
              step={errorFields?.oidc_client_id?.step}
            />
          </Box>
        );
      case 'oidc_client_secret':
        return (
          <Box key={field}>
            <Card>
              <TextField
                error={errors?.oidc_client_secret}
                label={errorFields?.oidc_client_secret?.label}
                name="oidc_client_secret"
                onChange={onInputChange}
                value={connection?.oidc_client_secret}
              />
            </Card>
            <StepButton
              setActiveStep={setActiveStep}
              step={errorFields?.oidc_client_secret?.step}
            />
          </Box>
        );
      case 'oidc_discovery_endpoint':
        return (
          <Box key={field}>
            <Card>
              <TextField
                error={errors?.oidc_discovery_endpoint}
                label={errorFields?.oidc_discovery_endpoint?.label}
                name="oidc_discovery_endpoint"
                onChange={onInputChange}
                value={connection?.oidc_discovery_endpoint}
              />
            </Card>

            <StepButton
              setActiveStep={setActiveStep}
              step={errorFields?.oidc_discovery_endpoint?.step}
            />
          </Box>
        );
      case 'oidc_redirect_uri':
        return (
          <Box key={field}>
            <Card>
              <TextField
                error={errors?.oidc_redirect_uri}
                label={errorFields?.oidc_redirect_uri?.label}
                name="oidc_redirect_uri"
                value={connection?.oidc_redirect_uri}
              />
            </Card>

            <StepButton
              setActiveStep={setActiveStep}
              step={errorFields?.oidc_redirect_uri?.step}
            />
          </Box>
        );
      case 'saml_idp_url':
        return (
          <Box key={field}>
            <Card>
              <TextField
                error={errors?.saml_idp_url}
                label={errorFields?.saml_idp_url?.label}
                name="saml_idp_url"
                onChange={onInputChange}
                value={connection?.saml_idp_url}
              />
            </Card>

            <StepButton
              setActiveStep={setActiveStep}
              step={errorFields?.saml_idp_url?.step}
            />
          </Box>
        );
      case 'saml_idp_metadata_url':
        return (
          <Box key={field}>
            <Card>
              <TextField
                error={errors?.saml_idp_metadata_url}
                label={errorFields?.saml_idp_metadata_url?.label}
                name="saml_idp_metadata_url"
                onChange={onInputChange}
                value={connection?.saml_idp_metadata_url}
              />
            </Card>

            <StepButton
              setActiveStep={setActiveStep}
              step={errorFields?.saml_idp_metadata_url?.step}
            />
          </Box>
        );
      case 'saml_entity_id':
        return (
          <Box key={field}>
            <Card>
              <TextField
                error={errors?.saml_entity_id}
                label={errorFields?.saml_entity_id?.label}
                name="saml_entity_id"
                onChange={onInputChange}
                value={connection?.saml_entity_id}
              />
            </Card>

            <StepButton
              setActiveStep={setActiveStep}
              step={errorFields?.saml_entity_id?.step}
            />
          </Box>
        );
      case 'saml_x509_certs':
        return (
          <Box key={field}>
            <Card>
              <FileField
                error={errors?.saml_x509_certs}
                filename="X.509 Certificate"
                label={errorFields?.saml_x509_certs?.label}
                name="saml_x509_certs"
                onUpload={onFileInput}
                value={connection?.saml_x509_certs?.[0]}
              />
            </Card>
            <StepButton
              setActiveStep={setActiveStep}
              step={errorFields?.saml_x509_certs?.step}
            />
          </Box>
        );
      case 'saml_idp_metadata_xml':
        const onXmlInput = async (
          event: ChangeEvent<HTMLInputElement>,
        ): Promise<void> => {
          if (event?.target?.files) {
            const file = event?.target?.files[0];

            if (file) {
              const reader = new FileReader();

              reader.onloadend = () => {
                setConnectionUpdatedFields({
                  saml_idp_metadata_xml: reader.result?.toString(),
                });
              };

              reader.readAsText(file);
            }
          }
        };

        return (
          <Box key={field}>
            <Card>
              <FileField
                error={errors?.saml_idp_metadata_xml}
                filename="metadata.xml"
                label={errorFields?.saml_idp_metadata_xml?.label}
                name="saml_idp_metadata_xml"
                onUpload={onXmlInput}
                value={connectionUpdatedFields?.saml_idp_metadata_xml}
              />
            </Card>
            <StepButton
              setActiveStep={setActiveStep}
              step={errorFields?.saml_x509_certs?.step}
            />
          </Box>
        );
      default:
        return null;
    }
  };

  if (errors && errorFields) {
    const fields = Object.keys(errorFields) as (keyof SSOErrors)[];

    const fieldComponents: ReactNode[] = fields.map((field) => {
      if (errors[field]) {
        return getFieldComponent(field);
      }
      return null;
    });

    return (
      <Article>
        <H1>Step {query.step}: Confirmation</H1>
        {errorCount > 0 && (
          <ErrorMessage>
            Please correct {errorCount} error
            {errorCount > 1 ? 's' : ''} and resubmit to complete your
            connection.
          </ErrorMessage>
        )}
        <Grid gridGap={space.spacing(5)} gridTemplateColumns="1fr">
          {fieldComponents}
          <Confirm
            buttonText="Complete My Connection"
            disabled={errorCount > 0}
            isLoading={isLoading}
            label="Click here to finalize your Connection."
            onClick={completeConnection}
          />
        </Grid>
      </Article>
    );
  }

  return (
    <Article style={{ textAlign: 'center' }}>
      <StyledIcon
        animate={{ opacity: 1, scale: 1 }}
        initial={{ opacity: 0, scale: 0.5 }}
        transition={{ delay: 0.1 }}
      >
        <IconCheck className="icon" />
      </StyledIcon>

      <Title
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        transition={{ delay: 0.15 }}
      >
        SSO Configuration Complete
      </Title>
      <Description
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        transition={{ delay: 0.2 }}
      >
        Your users can now sign-in with {providerLabel}.
      </Description>

      <motion.div
        animate={{ opacity: 1, scale: 1 }}
        data-testid="configuration-complete"
        initial={{ opacity: 0, scale: 0.5 }}
        transition={{ delay: 0.25 }}
      >
        <Button onClick={() => connection && testConnection(connection.id)}>
          Test Single Sign-On
        </Button>
      </motion.div>
    </Article>
  );
};

const StyledIcon = styled(motion.i)`
  border-radius: ${Radius.Rounded};
  width: 100px;
  height: 100px;
  display: inline-block;
  line-height: 100px;
  text-align: center;
  background-color: #27ae35;
  color: ${Colors.White};
  margin-top: ${space.spacing(10)};
  margin-bottom: ${space.spacing(10)};

  .icon {
    width: 54px;
    height: 54px;
    stroke-width: 3px;
  }
`;

const StyledStepButton = styled.button`
  appearance: none;
  background: none;
  border: none;
  color: ${(p) => p.theme.primary};
  font-size: 15px;
  font-weight: ${Weight.Medium};
  cursor: pointer;
`;

const StepButton = ({
  setActiveStep,
  step,
}: {
  setActiveStep: (step: number) => void;
  step?: number;
}) => (
  <StyledStepButton
    onClick={() => setActiveStep(step ?? 1)}
  >{`<- Show all of Step ${step}`}</StyledStepButton>
);

const ErrorMessage = styled.div`
  font-size: 17px;
  color: ${Colors.Red};
  line-height: 1.5;
  font-weight: ${Weight.Medium};
  margin-top: ${space.spacing(11)};
  margin-bottom: ${space.spacing(4)};
`;

const Title = motion(H1);
const Description = motion(Paragraph);
