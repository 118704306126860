import { ErrorFlashMessage } from 'components/flash-message';
import {
  DirectoryCustomAttributeFragment as DirectoryCustomAttribute,
  DirectoryFragment as Directory,
} from 'graphql/generated';
import { CoreAttribute, coreAttributeToLabel } from 'interfaces/core-attribute';
import React, { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { graphql } from 'utils/graphql';
import { AttributeContainer } from './attribute-container';
import { MapAttribute } from './map-attribute';

export type MapAttributesProps = {
  appName: string;
  directory: Directory;
  directoryCustomAttributes: DirectoryCustomAttribute[];
  readonly?: boolean;
} & (
  | { readonly: true }
  | {
      onFinishMapping: (updatedDirectory: Directory) => void;
      children: (props: {
        disabled: boolean;
        onClick: () => void;
      }) => JSX.Element;
    }
);

export const MapAttributes: FC<Readonly<MapAttributesProps>> = (props) => {
  const { appName, directory, directoryCustomAttributes, readonly } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [hasRequestError, setHasRequestError] = useState(false);
  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [customMappingValues, setCustomMappingValues] = useState(() =>
    initializeCustomMappingValues({ directory }),
  );
  const [coreMappingValues, setCoreMappingValues] = useState(() =>
    initializeCoreMappingValues(directory),
  );

  useEffect(() => {
    if (readonly) {
      setCustomMappingValues(initializeCustomMappingValues({ directory }));
      setCoreMappingValues(initializeCoreMappingValues(directory));
    }
  }, [readonly, directory, directoryCustomAttributes]);

  const requiredCustomAttributeSet: Set<string> = useMemo(
    () =>
      new Set(
        directoryCustomAttributes
          .filter((attribute) => attribute.isRequired)
          .map((attribute) => attribute.id),
      ),
    [directoryCustomAttributes],
  );

  const hasCoreAttributeMap = !!directory.attributeMap;
  const isValid = validateMappingValues({
    customMappingValues,
    coreMappingValues,
    hasCoreAttributeMap,
    requiredCustomAttributeSet,
  });
  const disabled = readonly || isLoading;

  const saveMappings = async () => {
    if (readonly) {
      return;
    }

    setHasRequestError(false);

    if (!isValid) {
      setShowValidationErrors(true);
      return;
    }

    setIsLoading(true);

    try {
      props.onFinishMapping(
        await updateDirectoryMappings({
          directory,
          coreMappingValues,
          customMappingValues,
        }),
      );
    } catch (e) {
      setHasRequestError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <AttributeContainer>
        <ColumnHeader>Directory Provider Value</ColumnHeader>
        <div style={{ flexBasis: '100px' }} />
        <ColumnHeader>{appName} Attribute</ColumnHeader>
      </AttributeContainer>

      {hasCoreAttributeMap &&
        Object.entries(coreMappingValues).map(([name, value]) => (
          <MapAttribute
            key={name}
            attributeId={name as CoreAttribute}
            disabled={disabled}
            name={coreAttributeToLabel(name as CoreAttribute)}
            onChange={({ attributeId, value }) =>
              setCoreMappingValues({
                ...coreMappingValues,
                [attributeId]: value,
              })
            }
            showValidationErrors={showValidationErrors}
            value={value}
          />
        ))}

      {directoryCustomAttributes.map((customAttribute) => (
        <MapAttribute
          key={customAttribute.id}
          attributeId={customAttribute.id}
          disabled={disabled}
          isRequired={customAttribute.isRequired}
          name={customAttribute.name}
          onChange={({ attributeId, value }) =>
            setCustomMappingValues(
              new Map(customMappingValues.set(attributeId, value)),
            )
          }
          showValidationErrors={showValidationErrors}
          value={customMappingValues.get(customAttribute.id) ?? ''}
        />
      ))}

      {!isValid && showValidationErrors && (
        <ErrorFlashMessage message="Make sure to add an attribute for each required mapping before proceeding." />
      )}

      {hasRequestError && (
        <ErrorFlashMessage message="Something went wrong saving your mappings. Please try again." />
      )}

      {!readonly &&
        props.children({ disabled: isLoading, onClick: saveMappings })}
    </div>
  );
};

const initializeCustomMappingValues = ({
  directory,
}: {
  directory: Directory;
}) =>
  new Map([
    ...directory.customAttributeMappings.map(
      ({ customAttributeId, attribute }) =>
        [customAttributeId, attribute] as const,
    ),
  ]);

const initializeCoreMappingValues = (
  directory: Directory,
): Record<CoreAttribute, string> => ({
  externalId: directory.attributeMap?.external_id_attribute ?? '',
  username: directory.attributeMap?.username_attribute ?? '',
  emails: directory.attributeMap?.emails_attribute ?? '',
  firstName: directory.attributeMap?.first_name_attribute ?? '',
  lastName: directory.attributeMap?.last_name_attribute ?? '',
  groupName: directory.attributeMap?.group_name_attribute ?? '',
});

const validateMappingValues = ({
  customMappingValues,
  coreMappingValues,
  hasCoreAttributeMap,
  requiredCustomAttributeSet,
}: {
  customMappingValues: Map<string, string>;
  coreMappingValues: Record<CoreAttribute, string>;
  hasCoreAttributeMap: boolean;
  requiredCustomAttributeSet: Set<string>;
}): boolean => {
  const areCoreAttributesValid =
    !hasCoreAttributeMap || Object.values(coreMappingValues).every(Boolean);

  const areCustomAttributesValid = [...requiredCustomAttributeSet.keys()].every(
    (value) => customMappingValues.get(value)?.trim(),
  );

  return areCoreAttributesValid && areCustomAttributesValid;
};

const updateDirectoryMappings = async ({
  directory,
  coreMappingValues,
  customMappingValues,
}: {
  directory: Directory;
  coreMappingValues: Record<CoreAttribute, string>;
  customMappingValues: Map<string, string>;
}): Promise<Directory> => {
  const customResponse = await graphql().SetDirectoryCustomAttributeMappings({
    input: {
      directoryId: directory.id,
      mappings: [...customMappingValues].map(
        ([customAttributeId, attribute]) => ({
          customAttributeId,
          attribute,
        }),
      ),
    },
  });

  let updatedAttributeMap = directory.attributeMap;

  if (!!directory.attributeMap) {
    const attributeMapResponse = await graphql().UpdateDirectoryAttributeMap({
      input: {
        directoryAttributeMapId: directory.attributeMap.id,
        externalIdAttribute: coreMappingValues.externalId,
        usernameAttribute: coreMappingValues.username,
        emailsAttribute: coreMappingValues.emails,
        firstNameAttribute: coreMappingValues.firstName,
        lastNameAttribute: coreMappingValues.lastName,
        groupNameAttribute: coreMappingValues.groupName,
      },
    });

    updatedAttributeMap =
      attributeMapResponse.data?.portal_updateDirectoryAttributeMap
        ?.directoryAttributeMap;
  }

  if (
    customResponse.data?.portal_setDirectoryCustomAttributeMappings
      .__typename === 'Portal_DirectoryCustomAttributeMappingsSet'
  ) {
    return {
      ...directory,
      attributeMap: updatedAttributeMap,
      customAttributeMappings:
        customResponse.data.portal_setDirectoryCustomAttributeMappings.mappings,
    };
  } else {
    throw new Error('Failed to update directory attribute mappings.');
  }
};

const ColumnHeader = styled.div`
  width: 100%;
  font-weight: bold;
`;
