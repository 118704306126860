import is from '@sindresorhus/is';
import { parseXML } from '@workos-inc/ui-kit';
import { Card } from 'components/card';
import { FileField } from 'components/fields';
import { Form } from 'components/form';
import { CopyInput } from 'components/input';
import { StoreContext } from 'components/store-provider';
import { Article, H1, Paragraph } from 'components/typography';
import { ConnectionFragment as Connection } from 'graphql/generated';
import { Error } from 'interfaces/errors';
import { ConnectionStepProps } from 'interfaces/step-props';
import React, { useContext, useEffect, useState } from 'react';

export const OktaOINConfigureIntegration: React.FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep }) => {
  const {
    connection: [, setConnection],
  } = useContext(StoreContext);

  const [metadataFileInputError, setMetadataFileInputError] = useState<Error>();
  const [metadataError, setMetadataError] = useState<string>('');
  const [metadataFile, setMetadataFile] = useState<string>('');

  const onMetadataFileInput = (data: Connection) => {
    if (
      !data.saml_idp_url ||
      data.saml_x509_certs?.filter((e) => e !== null).length === 0
    ) {
      return setMetadataFileInputError({
        message: 'File missing information',
        value: '',
      });
    }

    setConnection(data);
    setMetadataFileInputError(undefined);
  };

  useEffect(() => {
    if (metadataFile && metadataFileInputError) {
      setMetadataFileInputError({
        ...metadataFileInputError,
        value: metadataFile,
      });
    } else if (metadataError && !metadataFileInputError) {
      return setMetadataFileInputError({
        message: metadataError,
        value: metadataFile,
      });
    }
  }, [
    metadataFileInputError,
    metadataError,
    metadataFile,
    setMetadataFileInputError,
  ]);

  return (
    <Article>
      <H1>Step 1: Configure Okta Integration</H1>

      <Paragraph>Use this ACS URL to configure your Okta settings.</Paragraph>

      <CopyInput label="ACS URL" value={connection?.saml_acs_url} />

      <Form
        disabled={
          !connection?.saml_idp_url ||
          !connection?.saml_x509_certs ||
          !connection?.saml_entity_id
        }
        onSubmit={onNextStep}
      >
        <Card>
          <Paragraph>
            Then, upload your <strong>metadata.xml</strong> file to complete
            configuring your connection.
          </Paragraph>

          <FileField
            accept=".xml"
            error={metadataFileInputError}
            filename="Okta Metadata File"
            label="Okta Metadata File"
            name="metadata"
            onUpload={async (event: React.ChangeEvent<HTMLInputElement>) => {
              if (event?.target?.files) {
                const file = event?.target?.files[0];

                if (!file) {
                  return;
                }

                try {
                  if (file.type !== 'text/xml') {
                    throw new Error('Only .xml files are supported');
                  }

                  const fileContent = await parseXML(file);
                  setMetadataError('');
                  setMetadataFile(event.target.value);

                  onMetadataFileInput({ ...connection, ...fileContent });
                } catch (error) {
                  if (is.error(error)) {
                    setMetadataError(error.message);
                  }
                }
              }
            }}
            value={metadataFile}
          />
        </Card>
      </Form>
    </Article>
  );
};
