import { Colors, Radius, Row, space, Weight } from '@workos-inc/ui-kit';
import React from 'react';
import styled, { AnyStyledComponent } from 'styled-components';

export type FlashMessageProps = {
  BoxComponent?: AnyStyledComponent;
  message?: string;
  title?: string;
};

export const FlashMessage: React.FC<Readonly<FlashMessageProps>> = ({
  BoxComponent = FlashMessageBox,
  message,
  title,
}) => (
  <BoxComponent data-testid="flash-message">
    <div>
      {title && <FlashMessageTitle>{title}</FlashMessageTitle>}
      {message}
    </div>
  </BoxComponent>
);

export const FlashMessageBox = styled(Row)`
  align-items: center;
  background-color: #f8f9fc;
  border: 1px solid ${Colors.TonedLightGray};
  border-radius: ${Radius.Medium};
  color: ${Colors.TonedDarkGray};
  font-size: 13px;
  justify-content: space-between;
  margin-bottom: ${space.spacing(6)};
  padding: ${space.spacing(3)} ${space.spacing(4)};
  width: 100%;
`;

const FlashMessageTitle = styled.h4`
  font-weight: ${Weight.SemiBold};
  margin-bottom: ${space.spacing(2)};
`;
