import { Colors, IconArrowRight, Radius } from '@workos-inc/ui-kit';
import React from 'react';
import styled from 'styled-components';
import { AttributeContainer } from './attribute-container';

export interface MapAttributeProps<T extends string> {
  attributeId: T;
  disabled: boolean;
  isRequired?: boolean;
  name: string;
  onChange: (data: { attributeId: T; value: string }) => void;
  showValidationErrors: boolean;
  value: string;
}

export const MapAttribute = <T extends string>({
  attributeId,
  name,
  disabled,
  isRequired = true,
  onChange,
  showValidationErrors,
  value,
}: Readonly<MapAttributeProps<T>>): JSX.Element => {
  const inputId = `map-custom-attribute__${attributeId}`;
  const hasError = showValidationErrors && isRequired && !value;

  return (
    <AttributeContainer>
      <Input
        disabled={disabled}
        hasError={hasError}
        id={inputId}
        onChange={(event) =>
          onChange({
            attributeId,
            value: event.target.value,
          })
        }
        placeholder={`attribute_name${isRequired ? ' (required)' : ''}`}
        value={value}
      />

      <IconArrowRight style={{ flexBasis: '100px' }} />

      <InputLabel htmlFor={inputId}>{name}</InputLabel>
    </AttributeContainer>
  );
};

const Input = styled.input<{ hasError: boolean }>`
  width: 100%;
  border-radius: ${Radius.Medium};
  border: 1px solid
    ${({ hasError }) => (hasError ? Colors.Red : Colors.TonedLightGray)};
  padding: 8px 12px;

  &:disabled {
    background-color: ${Colors.TonedLighterGray};
    cursor: not-allowed;
  }

  &::placeholder {
    color: ${Colors.GrayLight};
  }
`;

const InputLabel = styled.label`
  width: 100%;
  border-radius: ${Radius.Medium};
  background-color: ${Colors.TonedLighterGray};
  border: 1px solid ${Colors.TonedLightGray};
  padding: 8px 12px 8px;
`;
